<template>
  <div>
    <v-select
      class="ma-2"
      v-model="accessUserBINWorking"
      :items="accessUserBINs"
      :hint="accessUserBINWorkingDescription != null ? accessUserBINWorkingDescription : ''"
      persistent-hint
      item-text="bin"
      return-object
      :label="stringValues.chooseWorkingBin[currentLanguage.language]"
      outlined
      dense
    ></v-select>
    <v-card max-height="200" width="256" style="overflow-y: auto">
      <v-navigation-drawer :permanent="$vuetify.breakpoint.lgAndUp">
        <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">Заказы</v-list-item-title>
          <v-list-item-subtitle>навигация</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>-->

        <v-divider></v-divider>

        <!-- <v-list dense nav>
          <v-list-item-subtitle>Навигация по заказам:</v-list-item-subtitle>
          <v-list-item-group mandatory v-model="orderNav">
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title>Показать все ({{orders.length}})</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="order in orders" :key="order.id" link>
              <code>{{ order.id }}</code>

              <v-list-item-content>
                <v-list-item-title>{{ order.filial.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list> -->
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      right: null,
      accessUserBINs: [],
    };
  },

  methods: {
    GetAccessUserBINs() {
      this.$store
        .dispatch("ACCESS_USER_BINS_BY_CONTEXT")
        .then((response) => {
          this.accessUserBINs = response;
          const workingBin = this.accessUserBINs.find((x) => x.working == true);
          this.$store.commit("SET_WORKING_BIN", workingBin);
          if (!workingBin) {
            let Snackbar = {
                  text: this.stringValues.chooseWorkingBin[this.currentLanguage.language],
                  timeout: 5000,
                  color: "warning",
                  show: true,
            };
            this.$store.commit("SET_SNACKBAR", Snackbar);
          }
        })
        .catch(() => {});
    },
    UpdateWorkingAccessUserBIN(setAccessUserBIN) {
      this.$store
        .dispatch("UPDATE_WORKING_ACCESS_USER_BIN", setAccessUserBIN.bin)
        .then(() => {
          this.$store.commit("SET_ORDER_RIGHT_TAB", "Новинки");
          if (this.$store.getters.ACTIVE_POINT != null) {
            this.$store
              .dispatch("ORDERS_BY_USER", this.$store.getters.ACTIVE_POINT)
              .then(() => {})
              .catch(() => {});
          } else {
            this.$store
              .dispatch("ORDERS")
              .then(() => {})
              .catch(() => {});
          }
          this.accessUserBINs.forEach(function (accessUserBIN) {
            if (accessUserBIN.bin == setAccessUserBIN.bin)
              accessUserBIN.working = true;
            else accessUserBIN.working = false;
          });
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  mounted() {
    this.GetAccessUserBINs();
  },
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
    orders: {
      get: function () {
        return this.$store.getters.ORDERS;
      },
    },
    orderNav: {
      get: function () {
        return this.$store.getters.ORDER_NAVIGATION;
      },
      set: function (orderIndex) {
    
        this.$store.commit("SET_ORDER_NAVIGATION", orderIndex);
      },
    },
    accessUserBINWorking: {
      get: function () {
        return this.$store.getters.WORKING_BIN;
      },
      set: function (setAccessUserBIN) {
        this.UpdateWorkingAccessUserBIN(setAccessUserBIN);
      },
    },
    accessUserBINWorkingDescription: {
      get: function () {
        return this.accessUserBINWorking?.companyBIN?.description;
      },
    },
  },
};
</script>