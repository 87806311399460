import axios from "axios";
import store from './../index'

export default {
    state: {
        priceShelflifeEndStatus: 30,
        priceFilial: [],
        priceGoodsId: {
            goods: null,
            prices: []
        },
        filialsInPrices: [],
        selectedFilialsInPrices: []
    },
    getters: {
        PRICE_FILIAL: state => {
            return state.priceFilial;
        },
        PRICE_BY_GOODS_ID: state => {
            return state.priceGoodsId;
        },
        PRICE_SHELFLIFE_END_STATUS: state => {
            return state.priceShelflifeEndStatus;
        },
        FILIALS_IN_PRICES: state => {
            return state.filialsInPrices;
        },
        SELECTED_FILIALS_IN_PRICES: state => {
            return state.selectedFilialsInPrices;
        }
    },
    mutations: {
        SET_PRICE_FILIAL: (state, payload) => {
            state.priceFilial = payload;
        },
        SET_PRICE_BY_GOODS_ID: (state, payload) => {
            state.priceGoodsId = payload;
        },
        SET_PRICE_SHELFLIFE_END_STATUS: (state, payload) => {
            state.priceShelflifeEndStatus = payload;
            localStorage.setItem('MIN_EXP_DAYS', JSON.stringify(payload));
        },
        SET_FILIALS_IN_PRICES: (state, payload) => {
            state.filialsInPrices = payload;
        },
        SET_SELECTED_FILIALS_IN_PRICES: (state, payload) => {
            state.selectedFilialsInPrices = payload;
        }
    },
    actions: {
        PRICE_BY_FILIAL_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Price/GetByFilialId?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_PRICE_FILIAL', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        PRICE_BY_GOODS_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Price/GetByGoodsId?goodsId=` + payload.goodsId)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {

                            let goodsPrice = {
                                goods: payload.goods,
                                prices: []
                            }

                            if (data.length)
                                goodsPrice.prices = data;
                            store.commit('SET_PRICE_BY_GOODS_ID', goodsPrice);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        GET_FILIALS_IN_PRICES: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Price/GetFilialsInPrices`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200){
                            commit('SET_FILIALS_IN_PRICES', data);
                            resolve(true)
                        }
                    })
                    .catch(error => {
                        window.console.error(error);
                        reject(error);
                    })
            });
        },
        UNLOAD_PRICE_BY_FILIAL_ID: (commit, payload) => {

            return new Promise((resolve, reject) => {
                axios
                    .post(`/Price/UnloadByFilialId?filialId=` + payload, {}, {
                        cancelToken: store.getters.CANCEL_TOKEN(payload)
                    })
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);

                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UNLOAD_PRICES_BY_FILIALS_ID: (commit, payload) => {

            return new Promise((resolve, reject) => {
                axios
                    .post(`/Price/UnloadByFilialsId`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);

                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMMIT_SELECTED_FILIALS_IN_PRICES: (commit, payload) => {
            store.commit('SET_SELECTED_FILIALS_IN_PRICES', payload);
        }
    }
};