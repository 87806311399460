import Vue from 'vue'
import Vuex from 'vuex'

import Token from "./modules/token";
import Snackbar from "./modules/snackbar";
import Distributor from "./modules/distributor";
import City from "./modules/city";
import Price from "./modules/price";
import Contract from "./modules/contract";
import ShippingAddress from "./modules/shippingAddress";
import CancelRequest from "./modules/cancelRequest";
import Order from "./modules/order";
import Report from "./modules/report";
import User from "./modules/user";
import Company from "./modules/company";
import Access from "./modules/access";
import Hub from "./modules/hub";
import Goods from "./modules/goods";
import Storage from "./modules/storage";
import Chat from "./modules/chat";
import Ad from "./modules/ad";
import Annotation from "./modules/annotation";
import Notification from "./modules/notification";
import APIRequest from "./modules/APIRequest";
import ObjectConfig from "./modules/objectConfig";
import Scheduler from "./modules/scheduler";
import Discount from "./modules/discount";
import Hangfire from "./modules/hangfire";
import HalykPay from "./modules/halykPay";
import Invoice from "./modules/invoice";
import Article from "./modules/article";
import UserAccess from "./modules/userAccess";
import PaymentTypes from "./modules/paymentTypes"
import Promo from "./modules/promo";
import Workload from "./modules/workload";
import ClientBonus from "./modules/clientBonus";
import Language from "./modules/language";
import Setup from "./modules/setup";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navDrawer: true,
  },
  modules: {
    token: Token,
    snackbar: Snackbar,
    distributor: Distributor,
    city: City,
    price: Price,
    contract: Contract,
    shippingAddress: ShippingAddress,
    cancelRequest: CancelRequest,
    order: Order,
    report: Report,
    user: User,
    company: Company,
    access: Access,
    hub: Hub,
    goods: Goods,
    storage: Storage,
    chat: Chat,
    ad: Ad,
    annotation: Annotation,
    notification: Notification,
    APIRequest: APIRequest,
    objectConfig: ObjectConfig,
    scheduler: Scheduler,
    discount: Discount,
    hangfire: Hangfire,
    halykPay: HalykPay,
    invoice: Invoice,
    article: Article,
    UserAccess: UserAccess,
    paymentTypes: PaymentTypes,
    promo: Promo,
    workload : Workload,
    clientBonus: ClientBonus,
    Language,
    setup: Setup
  }
})