import axios from "axios";
import store from './../index'

export default {
    state: {
        optimizeOrderOption: {
            minPriceExpiry: 3,
            stockEnable: false
        },
    },
    getters: {
        OPTIMIZE_ORDER_OPTIONS: state => {
            return state.optimizeOrderOption;
        },
    },
    mutations: {
        SET_OPTIMIZE_ORDER_OPTIONS: (state, payload) => {
            state.optimizeOrderOption = payload;
        },
    },
    actions: {
        USER_OPTIONS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/OptimizeOrder/GetOptionsByContextAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_OPTIMIZE_ORDER_OPTIONS', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_OPTIONS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/OptimizeOrder/UpdateOptionsAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_OPTIMIZE_ORDER_OPTIONS', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};