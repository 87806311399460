<template>
  <div
    data-app="true"
    class="v-application v-application--is-ltr theme--light full-sidebar"
    id="materialpro"
  >
    <div class="v-application--wrap">
      <div
        data-app="true"
        class="v-application v-application--is-ltr theme--light"
        id="app"
      >
        <div class="v-application--wrap">
          <section class="container fill-height justify-center" id="login">
            <div class="row justify-center">
              <div class="col-sm-8 col-lg-11 col-xl-7 col">
                <div class="elevation-4 v-card v-sheet theme--light pa-1">
                  <v-row no-gutters>
                    
                        <div
                          class="info d-none d-md-flex align-center justify-center col-lg-7 col rounded-sm"
                        >
                          <v-carousel
                            show-arrows-on-hover
                            cycle
                            hide-delimiter-background
                          >
                            <v-carousel-item>
                              <div class="d-none d-sm-block">
                                <div class="d-flex align-center pa-10">
                                  <div>
                                    <h2
                                      class="display-1 white--text font-weight-medium"
                                    >
                                      Pharmcenter.kz
                                    </h2>
                                    <h6 class="subtitle-1 mt-4 white--text">
                                      Система онлайн закупа лекарственных средств.
                                      Помощник в развитии аптечного бизнеса.
                                      Маркетинговая площадка для эксклюзивных акций.
                                      Возможность мониторинга цен на препараты.
                                    </h6>
                                    <v-btn
                                      class="ma-2"
                                      outlined
                                      dark
                                      large
                                      @click="OpenSite()"
                                    >
                                      Узнать больше
                                    </v-btn>
                                  </div>
                                </div>
                              </div>
                            </v-carousel-item>
                            <v-carousel-item>
                              <div class="pa-10">
                                <h2
                                  class="display-1 white--text font-weight-medium"
                                >Менеджера по работе с клиентами:</h2>
                              </div>
                              <v-row no-gutters>
                                <v-col cols="12">
                                  <v-simple-table dense class="my-1 ml-10">
                                    <template v-slot:default>
                                      <thead class="info">
                                        <tr>
                                          <th class="text-left white--text">ФИО</th>
                                          <th class="text-left white--text">Номер</th>
                                        </tr>
                                        </thead>
                                        <tbody class="info">
                                          <tr
                                            v-for="item in managerInfo"
                                            :key="item.FullName"
                                          >
                                            <td class="text-left white--text"><span>{{ item.FullName }}</span></td>
                                            <td class="text-left white--text"><span>{{ item.Phone }}</span></td>
                                          </tr>
                                        </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-col>
                              </v-row>
                            </v-carousel-item>
                          </v-carousel>
                        </div>


                    <!-- Вход в систему -->

                    <div class="col-lg-5 col" v-if="show">
                      <div class="pa-7 pa-sm-12">
                        <v-img
                          max-height="100"
                          contain
                          src="https://pharmit.kz/wp-content/themes/pharm/images/logo.png"
                        ></v-img>
                        <!-- <h2
                          class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                        >
                          Вход в систему
                        </h2> -->

                        <br />
                        <br />
                        <form class="v-form">
                          <v-text-field
                            label="Логин"
                            name="login"
                            prepend-icon="mdi-account"
                            type="text"
                            v-model.trim="account.login"
                            :rules="[rules.required]"
                            :autofocus="true"
                            :error-messages="modelState['Login']"
                            @keyup.enter="Authenticate"
                          />
                          <v-text-field
                            id="password"
                            label="Пароль"
                            name="password"
                            prepend-icon="mdi-lock"
                            v-model.trim="account.password"
                            :rules="[rules.required]"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPassword = !showPassword"
                            :type="showPassword ? 'text' : 'password'"
                            :error-messages="modelState['Password']"
                            @keyup.enter="Authenticate"
                          />

                          <v-btn
                            x-small
                            text
                            color="primary"
                            style="float: right"
                            @click="show = false"
                            >Забыли пароль?</v-btn
                          >
                          <br />
                          <br />
                          <v-btn
                            outlined
                            dark
                            block
                            color="primary"
                            @click="Authenticate"
                            :loading="loading"
                            depressed
                            large
                            >Вход в систему</v-btn
                          >
                        </form>
                      </div>
                    </div>

                    <!-- Восстановить пароль -->
                    <div class="col-lg-5 col" v-else>
                      <div class="pa-7 pa-sm-12">
                        <v-img
                          max-height="100"
                          contain
                          src="https://pharmit.kz/wp-content/themes/pharm/images/logo.png"
                        ></v-img>
                        <!-- <h2
                          class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                        >
                          Вход в систему
                        </h2> -->

                        <br />
                        <br />
                        <form class="v-form">
                          <v-text-field
                            label="Логин"
                            name="login"
                            prepend-icon="mdi-account"
                            type="text"
                            v-model="account.login"
                            :rules="[rules.required]"
                            :autofocus="true"
                            :error-messages="modelState['Login']"
                            @keyup.enter="ForgotPassword"
                          />
                          <v-text-field
                            label="E-mail"
                            name="email"
                            prepend-icon="mdi-account"
                            type="text"
                            v-model="account.email"
                            :rules="rules.emailRules"
                            :autofocus="true"
                            :error-messages="modelState['Email']"
                            @keyup.enter="ForgotPassword"
                          />

                          <v-btn
                            x-small
                            text
                            color="primary"
                            style="float: right"
                            @click="show = true"
                            :disabled="loading"
                            >Вход в систему</v-btn
                          >
                          <br />
                          <br />
                          <v-btn
                            outlined
                            dark
                            block
                            color="primary"
                            @click="ForgotPassword"
                            :loading="loading"
                            depressed
                            large
                            >Восстановить пароль</v-btn
                          >
                        </form>
                      </div>
                    </div>
                  </v-row>
                  <v-row>
                    <v-col class="col-12">
                      <v-img
                        class="mx-auto"
                        src="../../assets/img/astana-hub-logo.png"
                        contain
                        width="250"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    account: {
      login: "",
      password: "",
      email: "",
    },

    showPassword: false,
    rules: {
      required: (value) => !!value || "Поле обязательно для заполнения.",
      emailRules: [
        (v) => !!v || "Заполните поле",
        (v) => /.+@.+\..+/.test(v) || "E-mail должен быть действительным",
      ],
    },
    modelState: {},
    loading: false,
    show: true,
    managerInfo: [
      {
        FullName: 'Анель Карманова',
        Phone: '+7 707 335 14 68'
      },
      {
        FullName: 'Ельназ Кусаин',
        Phone: '+ 7 708 668 90 37'
      },
      {
        FullName: 'Гаухар Ахметова',
        Phone: '+7 708 672 77 08'
      },
      {
        FullName: 'Николай Карягин',
        Phone: '+7 747 187 40 96'
      },
      {
        FullName: 'Абилхаир Талгат',
        Phone: '+7 747 480 25 66'
      },
      {
        FullName: 'Екатерина Мишина',
        Phone: '+ 7 708 482 68 06'
      },
      {
        FullName: 'Игнат Дяченко',
        Phone: '+7 708 816 60 97'
      }
    ]
  }),
  methods: {
    Authenticate() {
      this.loading = true;
      this.modelState = {};

      this.$store
        .dispatch("CREATE_TOKENS", this.account)
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.errors) {
            this.modelState = error.response.data.errors;
          }
          this.error = true;
        });
    },
    OpenSite() {
      window.open("https://pharmit.kz");
    },
    ForgotPassword() {
      this.loading = true;
      this.modelState = {};

      this.$store
        .dispatch("FORGOT_PASSWORD", this.account)
        .then((response) => {
          this.loading = false;
          let Message = {
            text: response,
            timeout: 7000,
            color: "warning",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Message);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.errors) {
            this.modelState = error.response.data.errors;
          }
          this.error = true;
        });
    },
  },
  computed: {
    loginForm: {
      get: function () {
        return this.$store.getters.LOGIN_FORM;
      },
      set: function () {
        this.$store.commit("SET_LOGIN_FORM", false);
      },
    },
  },
};
</script>

<style>
</style>