import axios from "axios";
import store from './../index'

export default {
    state: {
        user: JSON.parse(localStorage.getItem('PHC_user')),
        users: [],
        userDialog: false,
        userRelations: {},
        userRoles: [],
        phoneTypes: [],
        loginForm: true,
        filialsOfUserFilter: [],
        workingBin: undefined
    },
    getters: {
        USER_DIALOG: state => {
            return state.userDialog
        },
        LOGIN_FORM: state => {
            return state.loginForm
        },
        USER: state => {
            return state.user
        },
        USER_RELATIONS: state => {
            return state.userRelations
        },
        USERS: state => {
            return state.users
        },
        USER_ROLES: state => {
            return state.userRoles
        },
        PHONE_TYPES: state => {
            return state.phoneTypes
        },
        FILIALS_OF_USER_FILTER: state => {
            return state.filialsOfUserFilter
        },
        WORKING_BIN: state => {
            return state.workingBin
        }
    },
    mutations: {
        SET_USER_DIALOG: (state, payload) => {
            state.userDialog = payload;
        },
        SET_USER: (state, payload) => {
            localStorage.setItem('PHC_user', JSON.stringify(payload));
            state.user = payload;
        },
        SET_USER_RELATIONS: (state, payload) => {
            state.userRelations = payload;
        },
        SET_USERS: (state, payload) => {
            state.users = payload;
        },
        SET_USER_ROLES: (state, payload) => {
            state.userRoles = payload;
        },
        SET_PHONE_TYPES: (state, payload) => {
            state.phoneTypes = payload;
        },
        SET_NEW_USER: (state, payload) => {
            state.users.push(payload);
        },
        SET_LOGIN_FORM: (state, payload) => {
            state.loginForm = payload;
        },
        SET_FILIALS_OF_USER_FILTER: (state, payload) => {
            state.filialsOfUserFilter = payload;
        },
        SET_WORKING_BIN: (state, payload) => {
            state.workingBin = payload;
        }
    },
    actions: {
        USER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetByContext`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {

                            let NewUserPhone = {
                                phoneTypeId: null,
                                phoneType: null,
                                phone: null,
                            };
                            let NewUserEmail = {
                                email: null,
                            };

                            if (!data?.userPhones?.length) {
                                data.userPhones.push(NewUserPhone);
                                store.commit("SET_USER_DIALOG", true);
                            }
                            if (!data?.userEmails?.length) {
                                data.userEmails.push(NewUserEmail);
                                store.commit("SET_USER_DIALOG", true);
                            }

                            store.commit("SET_USER", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        USER_RELATIONS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetRelationsByContext`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_USER_RELATIONS", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        USERS_BY_COMPANY_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetAllByCompanyId?companyId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        USERS_BY_COMPANY_ID_PAGING: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/User/GetAllByCompanyIdPagingAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200){
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        USERS_INSIDE_COMPANY: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetAllInsideCompany`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_USERS", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        USERS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/User/GetAll`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            // store.commit("SET_USERS", data);
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        USER_ROLES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetRoles`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_USER_ROLES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        PHONE_TYPES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetPhoneTypes`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_PHONE_TYPES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        CREATE_USER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/User/Create`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_NEW_USER', data);                          
                            let Success = {
                                text: 'Пользователь успешно создан ' + data.login,
                                timeout: 5000,
                                color: 'success',
                            }                          
                            store.commit('SET_SNACKBAR', Success);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_USER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/User/Update`,
                        payload
                    )
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_USER_DISCOUNTS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/User/UpdateUserDiscounts`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200){
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        UPDATE_USER_CONTEXT: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/User/UpdateContext`,
                        payload
                    )
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        EMAILS_TO_SEND_ORDERS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetEmailsToSendOrdersByFilialIdAsync?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        ACCESS_USER_BINS_BY_CONTEXT: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetAccessUserBINsByContext`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        ACCESS_USER_CITIES_BY_CONTEXT: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetAccessUserCitiesByContext`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200){
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        UPDATE_WORKING_ACCESS_USER_BIN: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/User/UpdateWorkingAccessUserBIN?accessUserBIN=` + payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            let Success = {
                                text: 'Рабочий БИН изменён',
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);
                            store.commit('SET_WORKING_BIN', payload);
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CREATE_EMAILS_TO_SEND_ORDERS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/User/CreateEmailsToSendOrdersAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        USER_SEARCH_BY_NAME: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetAllByContainsNameAsync?name=` + payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        FILIALS_OF_USER_FILTER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/User/GetFilialsOfUserFilter`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_FILIALS_OF_USER_FILTER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/User/UpdateFilialsOfUserFilter`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        FORGOT_PASSWORD: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/User/ForgotPassword`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        RESET_PASSWORD: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/User/ResetPassword?password=`+  payload )
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CREATE_FEEDBACK: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                .post(`User/CreateFeedback`, payload)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
            });
        }
    }
};