<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="text-h6 font-weight-bold">Настройка оптимизированного заказа</span>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="3" class="text-left">
                <span>Минимальный срок годности (месяц)</span>
            </v-col>
            <v-col cols="2">
                <v-text-field v-model="optimizeOption.minPriceExpiry" label="Введите срок" type="number" dense clearable
                    hide-details outlined prepend-icon="mdi-update">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn small color="primary" @click="UpdateOptions()">Сохранить</v-btn>
            </v-col>
        </v-row>
        <v-divider style="margin: 10px;"></v-divider>
    </v-container>
</template>

<script>
export default {
    inject: ["theme"],
    data: () => ({
    }),
    computed: {
        stringValues: {
            get() {
                return this.$store.getters.STRING_VALUES;
            },
        },
        currentLanguage: {
            get() {
                return this.$store.getters.CURRENT_LANG;
            }
        },
        optimizeOption: {
            get() {
                return this.$store.getters.OPTIMIZE_ORDER_OPTIONS;
            }
        },
    },
    methods: {
        UpdateOptions() {
            this.$store
                .dispatch("UPDATE_OPTIONS", this.optimizeOption)
                .then(() => {
                    var Snackbar = {
                        text: "Изменения сохранены",
                        timeout: 5000,
                        color: "success",
                        linkText: "",
                        show: true,
                    };

                    this.$store.commit("SET_SNACKBAR", Snackbar);
                })
                .catch(() => {
                    var Snackbar = {
                        text: "Ошибка",
                        timeout: 5000,
                        color: "red",
                        linkText: "",
                        show: true,
                    };
                    this.$store.commit("SET_SNACKBAR", Snackbar);
                });
        },
        GetOptionsByContext() {
            this.$store
                .dispatch("USER_OPTIONS")
                .then(() => {
                })
                .catch((error) => {
                    window.console.log(error);
                });
        }
    },
    mounted() {
        this.GetOptionsByContext();
    }
}
</script>