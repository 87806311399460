<template>
  <div id="form">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Прайсы

          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchFilials"
            prepend-icon="mdi-cloud-search-outline"
            label="Поиск"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            label="Фильтр поставщиков"
            item-text="name"
            item-value="id"
            :items="distributors"
            @change="GetFilialsByDistributorId"
            persistent-hint
            hint="Выберите поставщика"
            return-object
            clearable
          ></v-autocomplete>
        </v-card-title>

        <v-sheet v-if="firstLoad" class="px-3 pt-3 pb-3">
          <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="table-row-divider@5"
          ></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
        </v-sheet>

        <v-card v-show="!firstLoad">
          <v-form ref="listForm" lazy-validation>
            <v-col class="text-left">
              <v-card elevation="0">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="UploadPricesByFilialsId(selectedFilials)"
                        :disabled="selectedFilials.length == 0"
                      >
                        <v-icon>mdi-cloud-upload-outline</v-icon> -
                        {{ selectedFilials.length }}
                      </v-btn>
                    </template>
                    <span>Выгрузить прайсы выделенных филиалов</span>
                  </v-tooltip>
                </div>
              </v-card>
            </v-col>

            <v-data-table
              :headers="filialHeaders"
              v-model="selectedFilials"
              :items="filials"
              :items-per-page="5"
              class="elevation-2"
              :loading="loading"
              :search="searchFilials"
              show-select
              :footer-props="{
                'items-per-page-options': [5, 10, 20, 30, 40, -1],
              }"
            >
              <template v-slot:item.price="{ item }">
                <v-btn
                  small
                  @click="
                    dialog = !dialog;
                    GetPriceByFilialId(item);
                  "
                  :disabled="item.uploadingPrice"
                  color="primary"
                  >Прайс</v-btn
                >
              </template>

              <template v-slot:item.uploadPrice="{ item }">
                <v-btn
                  fab
                  small
                  class="ma-2"
                  @click="UploadPriceByFilialId(item)"
                  :loading="item.uploadingPrice"
                  :disabled="item.uploadingPrice"
                >
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  class="ma-2"
                  color="error"
                  :disabled="!item.uploadingPrice"
                  @click="CancelRequestById(item)"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.logPrice="{ item }">
                <v-btn
                  fab
                  small
                  class="ma-2"
                  @click="
                    GetAPIRequest(
                      (orderId = null),
                      (requestType = 'PRICE'),
                      (filialId = item.id)
                    )
                  "
                  :loading="item.uploadingPrice"
                  :disabled="item.uploadingPrice"
                >
                  <v-icon>mdi-record-circle</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-form>
        </v-card>

        <v-dialog
          v-model="dialog"
          class="elevation-20"
          transition="scale-transition"
          persistent
          scrollable
        >
          <v-card tile>
            <v-toolbar dense>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Прайс {{ currentFilial }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn text @click="dialog = false">Закрыть</v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-title>
              <code>
                <small>Количество позиции: {{ price.length }}</small>
              </code>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchInPrice"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                item-key="[index]"
                :headers="priceHeaders"
                :items="price"
                :search="searchInPrice"
                :items-per-page="10"
                :loading="loadingPrice"
                single-select
                fixed-header
                height="500"
              >
                <template v-slot:item.shelfLife="{ item }">{{
                  item.shelfLife.substr(0, 10) | toDate
                }}</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <OrderDialog></OrderDialog>

    <v-dialog
      v-model="apiRequestDialog"
      hide-overlay
      class="elevation-20"
      transition="scale-transition"
      width="1200px"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar dense>
          <v-btn icon @click="apiRequestDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Журнал</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text @click="apiRequestDialog = false">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-timeline dense>
            <v-timeline-item small>
              <v-card>
                <v-card-title>userId</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.userId }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestProtocol</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestProtocol }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestType</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestType }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>request</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.request }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestBody</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestBody }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestBIN</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestBIN }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestDateTime</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{
                      new Date(apiRequest.requestDateTime).toLocaleString()
                    }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>responseStatusCode</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.responseStatusCode }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>response</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">
                      <v-btn
                        small
                        download="response.xml"
                        :href="'data:,' + apiRequest.response"
                        color="primary"
                      >
                        Загрузить</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  inject: ["theme"],
  data: () => ({
    selectedFilials: [],
    dialog: false,
    filials: [],
    price: [],
    distributors: [],
    expanded: [],
    loading: false,
    loadingPrice: true,
    firstLoad: true,
    searchFilials: "",
    searchInPrice: "",
    currentFilial: "",
    filialHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Поставщик", value: "name" },
      { text: "Прайс", value: "price", sortable: false },
      { text: "Выгрузка / Отмена", value: "uploadPrice", sortable: false },
      { text: "Журнал", value: "logPrice", sortable: false },
    ],
    priceHeaders: [
      { text: "Id товара", sortable: true, value: "goods.id" },
      { text: "Полное название", sortable: true, value: "goods.fullName" },
      {
        text: "DistributorGoodsId",
        sortable: true,
        value: "distributorGoodsId",
      },
      {
        text: "DistributorGoodsName",
        sortable: true,
        value: "distributorGoodsName",
      },
      {
        text: "DistributorProducer",
        sortable: true,
        value: "distributorProducer",
      },
      { text: "Срок годности", sortable: true, value: "shelfLife" },
      { text: "Цена", value: "goodsPrice" },
      { text: "Упаковка", value: "pack" },
      { text: "Коробка", value: "box" },
      { text: "Кратность", value: "multiplicity" },
      { text: "Остаток", value: "stored" },
      { text: "Статус", value: "priceStatus" },
    ],
    apiRequest: {},
    apiRequestDialog: false,
  }),
  async mounted() {
    this.$store.dispatch("DISTRIBUTORS").then(() => {
      this.distributors = this.$store.getters.DISTRIBUTORS;
    });

    this.$store
      .dispatch("FILIALS")
      .then(() => {
        this.firstLoad = !this.firstLoad;
        this.filials = this.$store.getters.FILIALS;
      })
      .catch(() => {
        this.firstLoad = !this.firstLoad;
      });

    // this.$store.dispatch("CANCEL_REQUESTS").then(() => {});
  },
  methods: {
    async GetPriceByFilialId(filial) {
      this.price = [];
      this.searchInPrice = "";
      this.currentFilial = "";
      this.loadingPrice = true;
      this.$store
        .dispatch("PRICE_BY_FILIAL_ID", filial.id)
        .then(() => {
          this.price = this.$store.getters.PRICE_FILIAL;
          for (var i = 0; i < this.price.length; i++) {
            this.price[i].index = i;
          }
          this.currentFilial = filial.name;
          this.loadingPrice = false;
        })
        .catch(() => {
          this.loadingPrice = false;
        });
    },
    async GetFilialsByDistributorId(distributor) {
      if (distributor == undefined) this.filials = this.$store.getters.FILIALS;
      else
        this.filials = this.$store.getters.FILIALS_BY_DISTRIBUTOR_ID(
          distributor.id
        ).filials;
    },
    async UploadPriceByFilialId(item) {
      this.loading = true;
      item.uploadingPrice = true;

      const axios = require("axios").default;

      let Request = {
        id: item.id,
        cancelTokenSource: axios.CancelToken.source(),
      };

      window.console.log(Request);

      this.$store.commit("PUSH_REQUEST", Request);

      await this.$store
        .dispatch("UNLOAD_PRICE_BY_FILIAL_ID", item.id)
        .then(() => {
          let Success = {
            text: "Прайс загружен",
            timeout: 3000,
            color: "success",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          this.loading = false;
          item.uploadingPrice = false;
        });
    },
    async UploadPricesByFilialsId() {
      this.loading = true;
      var filialsId = this.selectedFilials.map((x) => x.id);
      window.console.log(filialsId);
      await this.$store
        .dispatch("UNLOAD_PRICES_BY_FILIALS_ID", filialsId)
        .then(() => {
          let Success = {
            text: "Выбранные прайсы загружены",
            timeout: 3000,
            color: "success",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async CancelRequestById(item) {
      let Throw = {
        id: item.id,
        message: "Выгрузка была отменена пользователем. Поставщик: " + item.name,
      };
      this.$store.dispatch("CANCEL_REQUEST_BY_ID", Throw).then(() => {
        this.loading = false;
        item.uploadingPrice = false;
      });
    },
    GetAPIRequest(orderId, requestType, filialId) {
      let APIRequest = {
        orderId: `${orderId ?? ""}`,
        filialId: `${filialId ?? ""}`,
        requestType,
      };
      window.console.log(APIRequest);
      this.$store
        .dispatch("API_REQUEST", APIRequest)
        .then((hasData) => {
          if (hasData) {
            this.apiRequest = hasData;
            this.apiRequestDialog = true;
          } else {
            let Empty = {
              text: "Журнал отсутствует",
              timeout: 5000,
              color: "warning",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Empty);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
  },
};
</script>
