<template>
  <div>
    <v-dialog v-model="orderDialog.show" hide-overlay class="elevation-10" transition="scale-transition"
      max-width="550px">
      <v-card>
        <v-card-title class="px-0 py-1">
          <v-spacer />
          <v-btn small @click="orderDialog.show = false" color="red" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-img lazy-src class="align-end grey" height="200px"
              :src="goodsImageLink + orderDialog.price.goods.id + '.jpg'" contain>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute>
                  <div>
                    <p class="subtitle-2 text-center">
                      {{ orderDialog.price.goods.fullName }}
                    </p>
                  </div>
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>
        <v-card-text>
          <v-list-item two-line align="center">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{
                orderDialog.price.filial.name
                }}</v-list-item-title>
              <v-list-item-subtitle>{{stringValues.provider[currentLanguage.language]}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <span v-if="orderDialog.price.stored > 0">{{
                  orderDialog.price.stored
                  }}</span>
                <span v-else>н/д</span></v-list-item-title>
              <v-list-item-subtitle>{{stringValues.remain[currentLanguage.language]}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{
                orderDialog.price.shelfLife.substr(0, 10) | toDate
                }}</v-list-item-title>
              <v-list-item-subtitle>{{stringValues.expireDate[currentLanguage.language]}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line align="center">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{
                orderDialog.price.goodsPrice | toDecimal
                }}</v-list-item-title>
              <v-list-item-subtitle>{{stringValues.price[currentLanguage.language]}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{
                orderDialog.price.userDiscount
                }}</v-list-item-title>
              <v-list-item-subtitle>{{stringValues.discount[currentLanguage.language]}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{
                (orderDialog.price.goodsPrice -
                (orderDialog.price.userDiscount *
                orderDialog.price.goodsPrice) /
                100)
                | toDecimal
                }}</v-list-item-title>
              <v-list-item-subtitle>{{stringValues.discountPrice[currentLanguage.language]}}</v-list-item-subtitle>
            </v-list-item-content>

            <!-- <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  :counter="10"
                  type="number"
                  v-model="orderDialog.goodsQuantity"
                  label="ОСтаток аптеки"
                  autofocus
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  :counter="10"
                  type="number"
                  v-model="orderDialog.goodsQuantity"
                  label="Розничная цена"
                  autofocus
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  :counter="10"
                  type="number"
                  v-model="orderDialog.goodsQuantity"
                  label="Рекоманд.заказ (мы считаем)"
                  autofocus
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  :counter="10"
                  type="number"
                  v-model="orderDialog.goodsQuantity"
                  label="сколько он зараб по этому товару за неделю [вы зараб на данном товаре за неделю (с одним расчетом)]"
                  autofocus
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>-->
          </v-list-item>
          <v-list-item two-line align="center">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <span
                  v-if="orderDialog.price.multiplicity > 0 && orderDialog.goodsQuantity % orderDialog.price.multiplicity != 0"
                  :style="{ color: 'red' }">
                  {{ orderDialog.price.multiplicity}}
                </span>
                <span v-else-if="orderDialog.price.multiplicity > 0">{{
                  orderDialog.price.multiplicity
                  }}</span>
                <span v-else>н/д</span></v-list-item-title>
              <v-list-item-subtitle>Кратность</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <template v-if="orderDialog.goodsQuantity > 0">
                <v-list-item-title class="font-weight-bold">{{
                  (orderDialog.goodsQuantity * orderDialog.price.goodsPrice)
                  | toDecimal
                  }}</v-list-item-title>
                <v-list-item-subtitle>{{stringValues.total[currentLanguage.language]}}</v-list-item-subtitle>
              </template>
            </v-list-item-content>
            <v-list-item-content>
              <template v-if="orderDialog.goodsQuantity > 0">
                <v-list-item-title class="font-weight-bold" v-if="orderDialog.selectedPaymentType != null">{{
                  (orderDialog.goodsQuantity * orderDialog.price.goodsPrice -
                  ((orderDialog.price.userDiscount +
                  selectedPaymentTypeResultDiscount) *
                  orderDialog.goodsQuantity *
                  orderDialog.price.goodsPrice) /
                  100)
                  | toDecimal
                  }}</v-list-item-title>
                <v-list-item-title class="font-weight-bold" v-else>{{
                  (orderDialog.goodsQuantity * orderDialog.price.goodsPrice -
                  (orderDialog.price.userDiscount *
                  orderDialog.goodsQuantity *
                  orderDialog.price.goodsPrice) /
                  100)
                  | toDecimal
                  }}</v-list-item-title>
                <v-list-item-subtitle>{{stringValues.totalWithDiscount[currentLanguage.language]}}</v-list-item-subtitle>
              </template>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line align="center">
            <v-list-item-content></v-list-item-content>
            <v-list-item-content></v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field :counter="10" type="number" v-model="orderDialog.goodsQuantity"
                  :label="stringValues.count[currentLanguage.language]" autofocus
                  @keyup.enter="enterBtnHandler()"></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item two-line align="center" v-if="orderDialog.price.priceFilialPayments != null &&
              orderDialog.price.priceFilialPayments.length > 0 &&
              this.$store.getters.ORDERS.filter(
                (x) =>
                  x.filialId == orderDialog.price.filialId &&
                  x.orderDetails[0].paymentType ==
                    orderDialog.selectedPaymentType
              ).length > 0
            ">
            <v-list-item-content>
              <v-list-item-title>
                <v-select name="ordersList" :items="orders" item-text="id" item-value="id" v-model="selectedOrder"
                  :label="stringValues.orderNumber[currentLanguage.language]" :prepend-icon="'mdi-cart-variant'"
                  hint="Выберите заказ" persistent-hint return-object></v-select>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item two-line align="center" v-if="orderDialog.price.priceFilialPayments != null
              && orderDialog.price.priceFilialPayments.length > 0">
            <v-list-item-content>
              <v-list-item-title>
                <v-select name="paymentTypes" :items="orderDialog.price.priceFilialPayments"
                  item-text="filialPayment.paymentTypeName" item-value="filialPayment.paymentType"
                  v-model="orderDialog.selectedPaymentType" label="Типы оплаты" :prepend-icon="'mdi-credit-card-fast'"
                  :rules="rules" :error-messages="modelState" hint="Выберите тип оплаты" persistent-hint></v-select>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line align="center" v-if="orderDialog.price.priceFilialPayments != null
              && orderDialog.price.priceFilialPayments.length > 0">
            <v-list-item-content>
              <v-list-item-subtitle class="text-overline primary--text">Условия</v-list-item-subtitle>
              <v-list-item-title>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Базовая скидка</th>
                        <th class="text-center">
                          Доп.скидка ( если сумма выше
                          <u>{{ selectedPaymentTypeSumMax }}</u> )
                        </th>
                        <th class="text-center">
                          Доп.скидка (если сумма выше
                          <u>{{ selectedPaymentTypeSumMin }}</u> и ниже
                          <u>{{ selectedPaymentTypeSumMax }}</u> )
                        </th>
                        <th class="text-center">
                          Доп.скидка (если сумма ниже
                          <u>{{ selectedPaymentTypeSumMin }}</u> )
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          {{ selectedPaymentTypeBaseDiscount + "%" }}
                        </td>
                        <td class="text-center">
                          {{ selectedPaymentTypeDiscountMax + "%" }}
                        </td>
                        <td class="text-center">
                          {{ selectedPaymentTypeDiscountBetween + "%" }}
                        </td>
                        <td class="text-center">
                          {{ selectedPaymentTypeDiscountMin + "%" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line align="center"
            v-if="orderDialog.price.priceFilialPayments != null && orderDialog.price.priceFilialPayments.length > 0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ selectedPaymentTypeResultDiscount + "%" }}
              </v-list-item-title>
              <v-list-item-subtitle>Скидка от типа оплаты</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item align="center" v-if="orderDialog.price.isPromotion">
            <v-list-item-content>
              <v-list-item-title>
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Описание акции:
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-wrap">
                      {{ orderDialog.price.promotionDescription }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-card-actions v-if="orderDialog.goodsQuantity > 0">
          <v-spacer v-if="
              !this.$store.getters.ORDERS.filter(
                (x) =>
                  x.filialId == orderDialog.price.filialId &&
                  x.orderDetails[0].paymentType ==
                    orderDialog.selectedPaymentType
              ).length
            ">
          </v-spacer>

          <v-btn small @click="CreateOrder()" text color="primary">{{
            stringValues.createNewOrder[currentLanguage.language] }}</v-btn>

          <!-- показывать кнопку если есть заказ(ы) по выбранному поставщику  -->

          <template v-if="
              this.$store.getters.ORDERS.filter(
                (x) =>
                  x.filialId == orderDialog.price.filialId &&
                  x.orderDetails[0].paymentType ==
                    orderDialog.selectedPaymentType
              ).length
            ">
            <v-spacer></v-spacer>
            <v-menu top offset-y :close-on-content-click="false" :close-on-click="false">
              <template v-slot:activator="{ on }">
                <v-btn small v-on="on" text class="ml-3" color="primary">Добавить в заказ</v-btn>
              </template>

              <v-list dense two-line max-width="220px">
                <template v-for="(order, index) in this.$store.getters.ORDERS.filter(
                    (x) =>
                      x.filialId == orderDialog.price.filialId &&
                      x.orderDetails[0].paymentType ==
                        orderDialog.selectedPaymentType
                  )">
                  <v-divider :key="index" v-if="index !== 0"></v-divider>
                  <v-list-item :key="-index">
                    <v-list-item-content>
                      <p class="text-sm-body-2">
                        К заказу
                        <code><a :href="'#order' + order.id">{{ order.id }}</a>
                        </code>
                      </p>
                      <v-list-item-subtitle>{{
                        order.createdDate.substr(0, 10) | toDate
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top v-if="
                          order.orderDetails.find(
                            (x) => x.goodsId == orderDialog.price.goods.id
                          )
                        ">
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn v-on="{ ...onTooltip }" icon @click="
                              orderDialog.show = false;
                              UpdateDetail(
                                order.orderDetails.find(
                                  (x) => x.goodsId == orderDialog.price.goods.id
                                )
                              );
                            ">
                            <v-icon large color="primary">mdi-circle-edit-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Изменить количество</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn icon v-on="{ ...onTooltip }" @click="
                              orderDialog.show = false;
                              CreateDetail(order.id);
                            ">
                            <v-icon large color="primary">mdi-plus-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Добавить</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn v-on="{ ...onTooltip }" icon @click="
                              orderListDialog = true;
                              GetDetails(order.id);
                            ">
                            <v-icon large color="primary">mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <span>Посмотреть заказ</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="orderListDialog" max-width="600px">
      <v-card shaped>
        <v-card-title>
          {{ stringValues.orderNumber[currentLanguage.language] }}:
          <code>{{ details[0].orderId }}</code>
        </v-card-title>
        <v-card-text>
          <v-simple-table height="300px" dense fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Имя товара</th>
                  <th class="text-left">{{ stringValues.price[currentLanguage.language] }}</th>
                  <th class="text-left">{{ stringValues.count[currentLanguage.language] }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="detail in details" :key="detail.name">
                  <td>{{ detail.goods.fullName }}</td>
                  <td>{{ detail.price }}</td>
                  <td>{{ detail.quantity }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="orderListDialog = false">{{ stringValues.close[currentLanguage.language] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    orderListDialog: false,
    details: [{ orderId: 0 }],
    selectedPaymentType: null,
    selectedPaymentTypeBaseDiscount: 0,
    selectedPaymentTypeSumMax: 0,
    selectedPaymentTypeSumMin: 0,
    selectedPaymentTypeDiscountMax: 0,
    selectedPaymentTypeDiscountMin: 0,
    selectedPaymentTypeDiscountBetween: 0,
    selectedPaymentTypeResultDiscount: 0,
    rules: [(value) => !!value || "Обязательное поле для заполнения"],
    modelState: ["Выберите тип оплаты"],
    selectedOrder: 0,
    orders: [],
  }),
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
    orderDialog: {
      get: function () {
        return this.$store.getters.ORDER_DIALOG;
      },
      set: function (value) {
        this.$store.commit("SET_ORDER_DIALOG", value);
      },
    },
    goodsImageLink: {
      get: function () {
        return "https://images.provisor.kz/goods/";
      },
    },
  },
  methods: {
    CreateOrder() {
      if (this.orderDialog.price.multiplicity > 0
        && this.orderDialog.goodsQuantity % this.orderDialog.price.multiplicity != 0) {
          let Warning = {
            text: 'Не соблюдены условия кратности товара!',
            timeout: 5000,
            color: 'warning',
            show: true
          }
          this.$store.commit('SET_SNACKBAR', Warning);
          return;
      }
      if (
        this.orderDialog.selectedPaymentType != null ||
        this.orderDialog.price.priceFilialPayments.length == 0
      ) {
        let Order = {
          FilialId: this.orderDialog.price.filialId,
          UserId: this.$store.getters.ACTIVE_POINT,
          OrderDetails: [
            {
              GoodsId: this.orderDialog.price.goodsId,
              Price: this.orderDialog.price.goodsPrice,
              ShelfLife: this.orderDialog.price.shelfLife,
              Quantity: this.orderDialog.goodsQuantity,
              Discount: this.orderDialog.price.userDiscount,
              DiscountPayment: this.selectedPaymentTypeResultDiscount,
              IsntDIscount: this.orderDialog.price.isntDiscount,
              DistributorGoodsId: this.orderDialog.price.distributorGoodsId,
              Batch: this.orderDialog.price.batch,
              PaymentTypeId: this.selectedPaymentType,
              Multiplicity: this.orderDialog.price.multiplicity,
              IsMarked: this.orderDialog.price.isMarked
            },
          ],
        };

        this.$store
          .dispatch("CREATE_ORDER", Order)
          .then((response) => {
            if (response == true) {
              let reOrderDetailId = this.$store.getters.RE_ORDER_DETAIL;
              if (reOrderDetailId.detailId != 0) {
                this.$store
                  .dispatch("DELETE_DETAILS", [
                    {
                      id: reOrderDetailId.detailId,
                      orderId: reOrderDetailId.orderId,
                    },
                  ])
                  .then(() => {
                    this.$store.dispatch("GET_RE_ORDER_DETAIL", {
                      detailId: 0,
                      orderId: 0,
                    });
                  })
                  .catch(() => {});
              }
            }
          })
          .catch(() => {});

        this.orderDialog.show = false;
      } else {
        this.modelState = ["Выберите тип оплаты"];
        this.orderDialog.show = true;
      }
    },
    GetDetails(orderId) {
      this.details = this.$store.getters.ORDERS.filter(
        (x) => x.id == orderId
      )[0].orderDetails;
    },
    CreateDetail(orderId) {
      let Detail = {
        OrderId: orderId,
        GoodsId: this.orderDialog.price.goodsId,
        Price: this.orderDialog.price.goodsPrice,
        ShelfLife: this.orderDialog.price.shelfLife,
        Quantity: this.orderDialog.goodsQuantity,
        Discount: this.orderDialog.price.userDiscount,
        DiscountPayment: this.selectedPaymentTypeResultDiscount,
        DistributorGoodsId: this.orderDialog.price.distributorGoodsId,
        IsntDIscount: this.orderDialog.price.isntDiscount,
        PaymentTypeId: this.selectedPaymentType,
        Batch: this.orderDialog.price.batch,
        Multiplicity: this.orderDialog.price.multiplicity,
        IsMarked: this.orderDialog.price.isMarked
      };

      this.$store
        .dispatch("CREATE_DETAIL", Detail)
        .then((response) => {
          if (response == true) {
            let reOrderDetailId = this.$store.getters.RE_ORDER_DETAIL;
            if (reOrderDetailId.detailId != 0) {
              this.$store
                .dispatch("DELETE_DETAILS", [
                  {
                    id: reOrderDetailId.detailId,
                    orderId: reOrderDetailId.orderId,
                  },
                ])
                .then(() => {
                  this.$store.dispatch("GET_RE_ORDER_DETAIL", {
                    detailId: 0,
                    orderId: 0,
                  });
                })
                .catch(() => {});
            }
          }
        })
        .catch(() => {});
    },

    UpdateDetail(detail) {
      let Detail = {
        Id: detail.id,
        OrderId: detail.orderId,
        GoodsId: this.orderDialog.price.goodsId,
        Price: this.orderDialog.price.goodsPrice,
        ShelfLife: this.orderDialog.price.shelfLife,
        Quantity: this.orderDialog.goodsQuantity,
        Discount: this.orderDialog.price.userDiscount,
        DiscountPayment: this.selectedPaymentTypeResultDiscount,
        DistributorGoodsId: this.orderDialog.price.distributorGoodsId,
        IsntDIscount: this.orderDialog.price.isntDiscount,
        PaymentTypeId: this.selectedPaymentType ?? 0,
        IsMarked: this.orderDialog.price.isMarked
      };

      this.$store
        .dispatch("UPDATE_DETAIL", Detail)
        .then(() => {
          detail.quantity = this.orderDialog.goodsQuantity;
          detail.discountPayment = this.selectedPaymentTypeResultDiscount;
          let reOrderDetailId = this.$store.getters.RE_ORDER_DETAIL;
          if (reOrderDetailId.detailId != 0) {
            this.$store
              .dispatch("DELETE_DETAILS", [
                {
                  id: reOrderDetailId.detailId,
                  orderId: reOrderDetailId.orderId,
                },
              ])
              .then(() => {
                this.$store.dispatch("GET_RE_ORDER_DETAIL", {
                  detailId: 0,
                  orderId: 0,
                });
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },

    InitializePaymentTypeClauses(selectedPaymentType) {
      if (selectedPaymentType != null) {
        var tempSelectedPaymentType =
          this.orderDialog.price.priceFilialPayments.find(
            (x) => x.filialPayment.paymentType == selectedPaymentType
          );

        this.selectedPaymentTypeBaseDiscount =
          tempSelectedPaymentType.filialPayment.discount;
        this.selectedPaymentTypeSumMax =
          tempSelectedPaymentType.filialPayment.sumMax;
        this.selectedPaymentTypeSumMin =
          tempSelectedPaymentType.filialPayment.sumMin;
        this.selectedPaymentTypeDiscountMax =
          tempSelectedPaymentType.filialPayment.discountMax;
        this.selectedPaymentTypeDiscountMin =
          tempSelectedPaymentType.filialPayment.discountMin;
        this.selectedPaymentTypeDiscountBetween =
          tempSelectedPaymentType.filialPayment.discountBetween;
        this.selectedPaymentTypeResultDiscount =
          tempSelectedPaymentType.filialPayment.discount;
      } else {
        this.selectedPaymentTypeBaseDiscount = 0;
        this.selectedPaymentTypeSumMax = 0;
        this.selectedPaymentTypeSumMin = 0;
        this.selectedPaymentTypeDiscountMax = 0;
        this.selectedPaymentTypeDiscountMin = 0;
        this.selectedPaymentTypeDiscountBetween = 0;
        this.selectedPaymentTypeResultDiscount = 0;
      }
    },
    enterBtnHandler() {
      if (this.orderDialog.goodsQuantity == null) return;
      let filialOrders = this.$store.getters.ORDERS.filter(
        (x) =>
          x.filialId == this.orderDialog.price.filialId &&
          x.orderDetails[0].paymentType == this.orderDialog.selectedPaymentType
      );

      if (filialOrders.length > 0) {
        filialOrders.sort((a, b) => {
          return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
        });
        let lastOrder = filialOrders[0];
        let existsGoods = lastOrder.orderDetails
          .map((x) => x.goodsId)
          .includes(this.orderDialog.price.goodsId);
        if (existsGoods) {
          let updatedDetail = lastOrder.orderDetails.find(
            (x) => x.goodsId == this.orderDialog.price.goodsId
          );
          this.orderDialog.show = false;
          this.UpdateDetail(updatedDetail);
        } else {
          this.orderDialog.show = false;
          this.CreateDetail(lastOrder.id);
        }
      } else {
        this.CreateOrder();
      }
    },
  },
  watch: {
    orderDialog: {
      handler: function (value) {
        if (value.selectedPaymentType != null) {
          this.modelState = [];
          this.selectedPaymentType = value.selectedPaymentType;

          this.InitializePaymentTypeClauses(value.selectedPaymentType);

          this.orders = this.$store.getters.ORDERS.filter(
            (x) =>
              x.filialId == value.price.filialId &&
              x.orderDetails[0].paymentType == value.selectedPaymentType
          );

          if (this.orders.length > 0) {
            this.selectedOrder = value.selectedOrder;
          }

          var orderSumByDiscount = 0;
          if (this.selectedOrder?.id > 0) {
            this.selectedOrder.orderDetails.forEach((element) => {
              var elementSumByDiscount = (
                element.quantity * element.price -
                (element.discount * element.quantity * element.price) / 100
              ).toFixed(2);
              orderSumByDiscount =
                parseFloat(orderSumByDiscount) +
                parseFloat(elementSumByDiscount);
            });
          }

          var currentSumByDiscount = (
            value.goodsQuantity * value.price.goodsPrice -
            (value.price.userDiscount *
              value.goodsQuantity *
              value.price.goodsPrice) /
              100
          ).toFixed(2);
          var sumByDiscount =
            parseFloat(orderSumByDiscount) + parseFloat(currentSumByDiscount);

          var tempSelectedPaymentTypeDiscount = 0;
          if (sumByDiscount > 0) {
            if (sumByDiscount < this.selectedPaymentTypeSumMin)
              tempSelectedPaymentTypeDiscount =
                this.selectedPaymentTypeDiscountMin;
            else if (
              sumByDiscount >= this.selectedPaymentTypeSumMin &&
              sumByDiscount < this.selectedPaymentTypeSumMax
            )
              tempSelectedPaymentTypeDiscount =
                this.selectedPaymentTypeDiscountBetween;
            else if (sumByDiscount >= this.selectedPaymentTypeSumMax)
              tempSelectedPaymentTypeDiscount =
                this.selectedPaymentTypeDiscountMax;
            else tempSelectedPaymentTypeDiscount = 0;
          }

          this.selectedPaymentTypeResultDiscount +=
            tempSelectedPaymentTypeDiscount;
        } else {
          this.modelState = ["Выберите тип оплаты"];
          this.InitializePaymentTypeClauses(null);
        }
      },
      deep: true,
    },
    selectedOrder: function (val) {
      this.orderDialog.selectedOrder = val;
    },
  },
};
</script>
